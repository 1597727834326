/* preparing for inevitable footer */
.footer-100vh {
	min-height: calc(100vh - 65px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

body {
	overflow-x: clip;
	background-color: #1f2937;
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: inherit;
}

::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background-color: #9ca3af;
}
